import { cacheStore } from '@/store/modules/cache';
import { commonStore } from '@/store/modules/commonLists';
import dayjs from 'dayjs';
// 事件上报参考 https://webeye.feishu.cn/sheets/shtcnFsDdjE5Ne4lspyvpfyhmEc?sheet=K4cCEU
// 数说上报接口
const dateFinder = {
  /**
   * 上报事件
   * @param {string} event
   * @param {object} param
   */
  pushEvent: function (event: string, param?: object) {
    let origin = '开发';
    if (window.location.origin.includes('hellowe-test.webeye.cn')) {
      origin = '测服';
    } else if (window.location.origin.includes('hellowe.webeye.cn')) {
      origin = '正服';
    }
    (window as any).collectEvent(event, {
      origin: origin,
      name: cacheStore.appInfo?.user.name,
      team: cacheStore.appInfo?.user.user_team_list.map((item) => item.team.name) || [],
      role: commonStore.participant || [],
      time: dayjs(new Date()).format('YYYYMMDDHHmm'),
      ...param,
    });
  },
  /**
   * 修改上报配置参数
   * @param {obejct} param
   */
  config: function (param: object) {
    (window as any).collectEvent('config', {
      ...param,
    });
  },
};

export default dateFinder;
