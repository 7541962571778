import workbench from './workbench';
import mmm from './mmm';
import erp from './erp';
import business from './business';
import report from './report';
import more from './more';
import admin from './admin';
import { EmbedMenu, MenuItem, RouteToMenu } from 'types/config';
import { cloneDeep, intersection } from 'lodash-es';
import { isEmptyData } from '@/utils/common';
import router from '@/router';
import { MENU_ORDER, isDev, isLocal } from '@/utils/const';

function recursiveSort(menu) {
  if (!menu || !Array.isArray(menu)) {
    return menu;
  }

  // 先对当前级别的菜单项进行排序
  menu.sort((a, b) => a.order - b.order);

  // 递归对子菜单进行排序
  menu.forEach((item) => {
    if (item.children) {
      item.children = recursiveSort(item.children);
    }
  });

  return menu;
}

export const menus: MenuItem[] = [workbench, mmm, business, erp, report, more, admin];

export let merge_menus: MenuItem[] = [workbench, mmm, business, erp, report, more, admin];

export const formatMenu = (data: MenuItem[]) => {
  const tempMenus: MenuItem[] = [];
  const FlatMenus = (menus: MenuItem[]) => {
    menus.forEach((item) => {
      tempMenus.push({
        ...item,
      });
      if (item.children) {
        FlatMenus(item.children);
      }
    });
  };
  FlatMenus(data);
  return tempMenus;
};
export const formatMenuByFn = (data: MenuItem[], fn: Fn) => {
  const tempMenus: MenuItem[] = [...data];
  const formatMenus = (menus: MenuItem[]) => {
    menus.forEach((item) => {
      fn(item);
      if (item.children) {
        formatMenus(item.children);
      }
    });
  };
  formatMenus(data);
  return tempMenus;
};

export const getFlatTitlePathMenus = (menus) => {
  const tempMenus: MenuItem[] = [];
  const FlatMenus = (menus: MenuItem[], parentTitle = '') => {
    menus.forEach((item) => {
      tempMenus.push({
        ...item,
        title: parentTitle !== '' ? `${parentTitle}-${item.title}` : `${item.title}`,
      });
      if (item.children) {
        const title = parentTitle !== '' ? `${parentTitle}-${item.title}` : `${item.title}`;
        FlatMenus(item.children, title);
      }
    });
  };
  FlatMenus(menus);
  return tempMenus;
};
export const getCasCaderMenus = (menus: MenuItem[]) => {
  const formatMenuPath = (data: MenuItem[]) => {
    return data.map((mtem) => {
      const result = {
        ...mtem,
        id: mtem.path,
        name: mtem.title,
        value: mtem.path,
        label: mtem.title,
      };
      if (mtem.children) {
        result.children = formatMenuPath(mtem.children);
      }
      return result;
    });
  };
  return formatMenuPath(menus);
};
export const getFlatPathMenus = (menus: MenuItem[]) => {
  const clone_menus = cloneDeep(menus);
  const formatMenuPath = (data: MenuItem[], parentPath = '/') => {
    return data.map((mtem) => {
      const result = {
        ...mtem,
        path: `${parentPath}${mtem.path}`,
      };
      if (mtem.children) {
        result.children = formatMenuPath(mtem.children, `${parentPath}${mtem.path}/`);
      }
      return result;
    });
  };
  return formatMenuPath(clone_menus);
};
export const getHasAuthMenus = (menus: MenuItem[], routes: RouteToMenu[], no_filter = false) => {
  const clone_menus = cloneDeep(menus);
  const getHasAuthMenuItems = (data: MenuItem[]) => {
    return data.filter((item) => {
      let findItem: undefined | RouteToMenu = undefined;
      if (item.children) {
        findItem = routes.find((route_item) => route_item.union_menu_key.includes(item.path));
      } else {
        findItem = routes.find((route_item) => route_item.union_menu_key === item.path);
      }
      const origin_item = cloneDeep(item);
      if (item.children) {
        item.children = getHasAuthMenuItems(item.children);
      }
      return (
        (!isEmptyData(findItem) &&
          (isEmptyData(origin_item?.children) ||
            (!isEmptyData(origin_item?.children) && item.children?.length !== 0))) ||
        no_filter
      );
    });
  };
  return getHasAuthMenuItems(clone_menus);
};
export function mergeMenuItem(objMenus: MenuItem, srcMenus: MenuItem) {
  if (isEmptyData(srcMenus.children)) {
    return objMenus;
  } else {
    if (isEmptyData(objMenus.children)) {
      objMenus.children = srcMenus.children;
    } else {
      const obj_child_path_list = objMenus.children?.map((item) => item.path);
      srcMenus.children?.forEach((srcItem) => {
        if (!obj_child_path_list?.includes(srcItem.path)) {
          objMenus.children?.push(srcItem);
        } else {
          const findItem = objMenus.children!.find((item) => srcItem.path === item.path)!;
          mergeMenuItem(findItem, srcItem);
        }
      });
    }
  }
}
/**
 * 插入菜单
 * @param custom_menu_list
 */
export function getAllHWRoutesAndMenus(custom_menu_list: { id: number; value: string }[]) {
  const returnValue: { menus: MenuItem[]; routes: RouteToMenu[] } = {
    menus: [],
    routes: [],
  };
  const embedMenus = cloneDeep(custom_menu_list); //插入的子应用菜单
  let cloneMenus = cloneDeep(merge_menus); // HW4自身菜单
  cloneMenus = formatMenuByFn(cloneMenus, (item) => {
    if (!item.order) {
      item.order = MENU_ORDER.DEFAULT;
    }
    return item;
  });
  const originRoutes = router.getRoutes(); // HW4自身路由
  returnValue.routes = originRoutes.map((item) => {
    let mount_menu_key: string[] = [];
    if (isEmptyData(item.meta.mount_menu_key)) {
      mount_menu_key = item.path.split('/');
      if (mount_menu_key[0] === '') {
        mount_menu_key.splice(0, 1);
      }
    } else {
      mount_menu_key = item.meta.mount_menu_key as string[];
    }
    const temp = {
      route_path: item.path,
      auth: isEmptyData(item.meta.auth) ? [] : (item.meta.auth as string[]),
      mount_menu_key,
      union_menu_key: `/${mount_menu_key.join('/')}`,
    };
    return temp;
  });
  embedMenus.forEach((embedMenuConfig) => {
    const embedConfigValue: EmbedMenu = JSON.parse(embedMenuConfig.value);
    if (!isEmptyData(embedConfigValue.routes)) {
      returnValue.routes.push(...embedConfigValue.routes);
      embedConfigValue.menus.forEach((embedMenu) => {
        const formatEmbedMenu = formatMenuByFn([embedMenu], (item) => {
          if (!item.order) {
            item.order = MENU_ORDER.EMBED;
          }
          return item;
        })[0];
        const findItem = cloneMenus.find((menu) => menu.path === formatEmbedMenu.path);
        if (!isEmptyData(findItem)) {
          mergeMenuItem(findItem!, formatEmbedMenu);
        }
      });
    }
  });
  returnValue.menus = cloneMenus;
  return returnValue;
}
/**
 * 用于生成路由和菜单(根据权限过滤)
 * @param all_data
 * @param user_permission_list
 */
export function getFilteredHWRoutesAndMenus(
  all_data: { menus: MenuItem[]; routes: RouteToMenu[] },
  user_permission_list: string[]
) {
  const returnValue: { menus: MenuItem[]; routes: RouteToMenu[] } = {
    menus: [],
    routes: [],
  };

  console.log(all_data.routes);
  if (user_permission_list.includes('RoleOne')) {
    returnValue.routes = all_data.routes;
  } else if (user_permission_list.includes('RoleGod')) {
    returnValue.routes = all_data.routes.filter((item) => {
      const isTheOneMenu = item.auth && item.auth.length === 1 && item.auth[0] === 'RoleOne';
      return !isTheOneMenu;
    });
  } else if (user_permission_list.includes('RoleAdmin')) {
    returnValue.routes = all_data.routes.filter((item) => {
      const isTheOneMenu = item.auth && item.auth.length === 1 && item.auth[0] === 'RoleOne';
      const hasAuth = intersection(item.auth, user_permission_list).length > 0;
      const isAdminMenu = item.union_menu_key.includes('/admin/');
      return !isTheOneMenu && (!isAdminMenu || hasAuth);
    });
  } else {
    returnValue.routes = all_data.routes.filter((item) => {
      return (
        isEmptyData(item.auth) ||
        intersection(item.auth, user_permission_list).length > 0 ||
        ((isDev || isLocal) && item.auth[0] === 'HasMenu_QuickLogin')
      );
    });
  }
  all_data.menus = getHasAuthMenus(getFlatPathMenus(all_data.menus), returnValue.routes);
  returnValue.menus = recursiveSort(all_data.menus);
  return returnValue;
}

export function addMenusByCustomReportList(list) {
  const cloneList = [...list].filter((item) => !item.auto_generate_url);
  merge_menus = cloneDeep(menus);
  cloneList.forEach((item) => {
    let report_menu: Recordable = {};
    try {
      report_menu = JSON.parse(item.report_menu);
    } catch (e) {
      return;
    }
    const menu_parent_path_list = report_menu.parent_menu_path;
    const menu_child = {
      path: report_menu.menu_path,
      title: item.name,
      order: report_menu.order ? report_menu.order : MENU_ORDER.DASHBOARD,
    };
    let parent_menu: Recordable = {
      children: [],
    };
    for (let i = 0; i < menu_parent_path_list.length; i++) {
      if (!isEmptyData(parent_menu) && !isEmptyData(parent_menu.children)) {
        parent_menu = parent_menu.children.find((item) => item.path === menu_parent_path_list[i]);
      } else {
        parent_menu = merge_menus.find((item) => item.path === menu_parent_path_list[i])!;
      }
    }
    if (!isEmptyData(parent_menu)) {
      parent_menu.children.push(menu_child);
    }
  });
}
